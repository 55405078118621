// /config/api.js
import CONFIG from "@/config/config";

import * as usesApi from './api/users';
import * as blockchainApi from './api/blockchain';
import * as indexApi from './api/index';

export default {
  baseUrl: CONFIG.BASE_URL,
  users: usesApi,
  blockchain: blockchainApi,
  index: indexApi,
};


