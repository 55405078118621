// /i18n/index.js
import { createI18n } from "vue-i18n";
import store from "@/store"; 

// 动态加载语言文件
const messages = {};
const files = require.context("./lang", true, /\.json$/);

// 遍历导入所有语言文件
files.keys().forEach((key) => {
  const lang = key.replace(/(\.\/|\.json)/g, ""); // 去掉前缀和后缀
  messages[lang] = files(key); // 动态设置语言包
});

// 获取用户设置的语言，默认是 'en'
let lang = store.state.lang ?? "en";

const i18n = createI18n({
  locale: lang, // 设置当前语言
  messages, // 语言包
});

export default i18n;
