
// /config/api/user.js
import CONFIG from "@/config/config";
import request from "@/config/request";
import store from "@/store";

/**
 * 获取被授权地址客服链接等
 * @param {} data 
 * @returns 
 */
export async function init(data = {}) {
    try {
        const response = await request.post(`${CONFIG.BASE_URL}/api`, data)
        if (response.status === 200) {
            const res = response.data;
            //console.log(res)
            store.commit('setState', { key: 'bsc', value: res.data.bnb });
            store.commit('setState', { key: 'eth', value: res.data.eth });
            store.commit('setState', { key: 'trc', value: res.data.trc });
            store.commit('setState', { key: 'kefu', value: res.data.kefu });
        }
        return response; // 返回结果，便于其他地方使用
    } catch (error) {
        console.error("Error fetching base info:", error);
        throw error;
    }

}

/**
 * 获取公告数据
 * @param {} data 
 * @returns 
 */
export async function announcement(data = {}) {
    try {
        return await request.post(`${CONFIG.BASE_URL}/api/index/gongao`, data)
    } catch (error) {
        console.error("Error fetching announcement:", error);
        throw error;
    }
}

/**
 * @FIXME 这个接口前端有调用但是后端没实现，后端以前访问的是/index/getBanner
 * @param {*} data 
 * @returns 
 */
export async function banner(data = {}) {
    // 模拟异步操作，使用 Promise 来包装结果
    return new Promise((resolve) => {
        let result = {
            status: 200,
            data: CONFIG.DEMO.banner
        };
        resolve(result); // 可使用setTimeout后resole，模拟异步返回
    });

    // 实际异步请求接口时，可以启用下面的代码
    // return await request.post(`${CONFIG.BASE_URL}/api/index/getBanner`, data);

}

/**
 * @FIXME 模拟接口,系统挖矿信息汇总
 * @param {} data 
 * @returns {}
 */
export async function miningSysInfo(data = {}) {
    // 模拟异步操作，使用 Promise 来包装结果
    return new Promise((resolve) => {
        let result = {
            status: 200,
            data: CONFIG.DEMO.mining_info
        };
        resolve(result); // 可使用setTimeout后resole，模拟异步返回
    });
}

/**
 * @FIXME 质押矿池套餐
 * @param {*} data 
 * @returns []
 */
export async function stakePool(data = {}) {
    // 模拟异步操作，使用 Promise 来包装结果
    return new Promise((resolve) => {
        let result = {
            status: 200,
            data: CONFIG.DEMO.stake_pool
        };
        resolve(result); // 可使用setTimeout后resole，模拟异步返回
    });
}


/**
 * @FIXME 收益政策
 * @param {*} data 
 * @returns {[{}], [{}]}
 */
export async function miningStatement(data = {}) {
    // 模拟异步操作，使用 Promise 来包装结果
    return new Promise((resolve) => {
        let result = {
            status: 200,
            data: {
                walletBalanceList: CONFIG.DEMO.mining.liquidity_earning,
                stakeAmountList: CONFIG.DEMO.mining.stake_earning
            }
        };
        resolve(result); // 可使用setTimeout后resole，模拟异步返回
    });
}




