// /config/util.js
import * as Date from './util/date';
import * as Router from './util/router';
import * as Tools from './util/tools';

export default {
  date: Date,
  router: Router,
  tools: Tools,
};


