// main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// vant Ui
import Vant from "vant";
import "vant/lib/index.css";

// config配置
import Config from "@/config/config";
import request from "@/config/request";
import Api from "@/config/api";
import Util from "@/config/util"

// 多语言
import i18n from "./i18n";

const app = createApp(App);

// 检查当前环境是否是开发模式，如果是，则启用 Vue Devtools
if (process.env.NODE_ENV === 'development') {
  app.config.devtools = true;
}

// 自动注册以 df- 开头的组件

//   Vite 或 Webpack 5 时使用 import.meta.glob 自动注册以 df- 开头的组件
// const components = import.meta.glob('./components/df-*.vue');
// for (const path in components) {
//   components[path]().then((module) => {
//     // 获取组件名并全局注册
//     const componentName = module.default.name || path.split('/').pop().replace('.vue', '');
//     app.component(componentName, module.default);
//   });
// }

//  Vue CLI 使用 require.context 自动注册以 df- 开头的组件
const requireComponent = require.context('./components', true, /df-[\w-]+\.vue$/);
requireComponent.keys().forEach((fileName) => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName);
  // 获取组件的 PascalCase 名称
  const componentName = fileName.split('/').pop().replace(/\.\w+$/, ''); // 移除扩展名
  // 全局注册组件
  app.component(componentName, componentConfig.default || componentConfig);
});


// 全局注册 Vuex getters
app.config.globalProperties.$getters = store.getters;
// 全局注册工具函数
app.config.globalProperties.$util = Util;

app.config.globalProperties.$config = Config;

app.config.globalProperties.$request = request;

app.config.globalProperties.$api = Api;

app
  .use(store)
  .use(router)
  .use(Vant)
  .use(i18n)
  .mount("#app");
