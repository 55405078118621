<template>
    <div class="auto-resize" :style="{ fontSize: fontSize + 'px' }">
      {{ number }}
    </div>
  </template>
  
  <script>
  export default {
    name: 'df-number-resize',
    props: {
      number: {
        type: [Number, String],
        required: true
      },
      maxFontSize: {
        type: Number,
        default: 30 // 最大字体大小
      },
      minFontSize: {
        type: Number,
        default: 16 // 最小字体大小
      },
      thresholdLength: {
        type: Number,
        default: 10 // 字符长度超过此值时开始缩小字体
      },
      transitionDuration: {
        type: Number,
        default: 300 // 字体变化的过渡时间（毫秒）
      }
    },
    data() {
      return {
        fontSize: this.maxFontSize // 初始化为最大字体大小
      };
    },
    watch: {
      number(newValue) {
        this.adjustFontSize(newValue);
      }
    },
    mounted() {
      this.adjustFontSize(this.number);
    },
    methods: {
      adjustFontSize(number) {
        const length = number.toString().length;
  
        // 根据数字长度计算字体大小，字符越多，字体越小
        if (length <= this.thresholdLength) {
          this.fontSize = this.maxFontSize;
        } else {
          const shrinkFactor = (this.maxFontSize - this.minFontSize) / (length - this.thresholdLength);
          this.fontSize = Math.max(this.minFontSize, this.maxFontSize - shrinkFactor * (length - this.thresholdLength));
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .auto-resize {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* transition: font-size 0.3s ease; /* 平滑过渡效果 */
  }
  </style>
  