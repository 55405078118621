import Axios from "axios";
import api from "./api";
import store from "@/store"; // 导入你的 Vuex store
// ***************** @FIXME 指纹功能 *******************
import FingerprintJS from '@fingerprintjs/fingerprintjs'
const fpPromise = FingerprintJS.load()

;(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()
    //console.log('1', result.visitorId);
  })()
// ************************************

const instance = Axios.create({
    baseURL: api.baseUrl,
    timeout: 100000
});

// Header常量
const HEADER_TOKEN = 'token';
const HEADER_ADDR = 'address';
const HEADER_CHAIN = 'chain';

instance.interceptors.request.use(config => {
    // 获取 token 和其他状态数据，添加默认值
    const token = store.state.token || 'defaultToken'; 
    const address = store.state.address || 'defaultAddress';
    const chain = store.state.chain || 'defaultChain';

    // 设置请求头
    config.headers[HEADER_TOKEN] = token;
    config.headers[HEADER_ADDR] = address;
    config.headers[HEADER_CHAIN] = chain;

    return config;
}, error => {
    return Promise.reject(error);
});

// 如果需要，添加响应拦截器
instance.interceptors.response.use(response => {
    // 对响应数据进行处理
    return response;
}, error => {
    // 处理响应错误
    console.error("Response error:", error);
    return Promise.reject(error);
});

export default instance;
