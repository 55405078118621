//getters.js
import Util from "@/config/util"
import { isNumeric } from "vant/lib/utils";

export const getters = {
  account: (state) => {
    const address = state.address;

    // 使用 toFixed(8) 保留6位小数，然后用 parseFloat() 去掉多余的零
    const total_income = parseFloat(state.users?.leiji || 0)
      + parseFloat(state.users?.tiqu || 0)
      + parseFloat(state.users?.freeze || 0)

    // @FIXME state.balance是前端获取到的余额

    return {
      balance: isNumeric(state.users?.balance) ? Util.tools.floatFormat(state.users.balance) : '-',// 钱包余额
      vip_level: state.users?.vip_level || '-', // VIP等级
      total_income: Util.tools.floatFormat(total_income), // 总收益
      total_interest: Util.tools.floatFormat(state.users?.leiji), // 累计提息(已提取成功)
      interest_balance: Util.tools.floatFormat(state.users?.tiqu), // 利息余额(未提取的-可以提取的最大值)
      interest_frozen: Util.tools.floatFormat(state.users?.freeze), // 利息冻结(提取中的)
      pledge_amount: Util.tools.floatFormat(state.users?.pledge_amount) || '-', // 质押金额
      daily_interest: Util.tools.floatFormat(state.users?.daily_interest), // 日利息（预期）
      address: address,
      address_masked: Util.tools.maskAddress(address),
    };
  },
  address: (state) => {
    return state.address;
  },
  chain: (state) => {
    return state.chain;
  },
};