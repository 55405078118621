// /config/util/router.js
import router from "@/router";

/**
 * 站内页面跳转
 * @param {*} path 
 */
export function goto(path) {
    router.push(path);
}

/**
 * 站外跳转
 * @param {*} url 
 */
export function jump(url) {
    window.location.href = url;
}
