// actions.js
import Web3 from "web3";

export const actions = {
  updateAddr: function ({ dispatch, commit }, address) {
    commit("setAddr", address);
  },
  updateBalance: function ({ dispatch, commit }, balance) {
    commit("setBalance", balance); //注意这里设置的balance是前端的数据，用于和users.balance对比
  },
  updateChain: function ({ dispatch, commit }, index) {
    commit("setChain", index);
  },
  updateLang: function ({ dispatch, commit }, index) {
    commit("setLang", index);
  },
  // 通用接口
  updateState: function ({ dispatch, commit }, {key, value}) {
    commit('setState', {key: key, value: value});
  },
  // 获取ERC20钱包地址
  connectWallet: async function ({ dispatch, commit }) {
    let address = "";
    try {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        address = accounts[0]; // 获取第一个账户地址

      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
        const accounts = await window.web3.eth.getAccounts(); // 使用 web3 获取账户地址
        address = accounts[0];

      } else {
        console.log("Non-Ethereum browser detected. You should consider trying MetaMask!");
        return { success: false, message: "Non-Ethereum browser detected" };
      }

      // 如果地址获取成功，存储地址
      if (address) {
        commit("setAddr", address); // 更新Vuex中的地址
        return { success: true, address }; // 返回成功状态和地址
      } else {
        return { success: false, message: "Failed to get address" };
      }

    } catch (error) {
      console.log("connectWallet-err:", error);
      return { success: false, message: error.message }; // 返回错误信息
    }
  },
  // 可以在这里添加更多的 actions
};
