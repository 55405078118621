// config/config.js
const BASE_URL = process.env.VUE_APP_BASE_URL;

// USDT合约地址
const USDT_CONTRACT_ADDRESS = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";
const USDT_CONTRACT_TRCADDRESS = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";
const USDT_CONTRACT_ERCADDRESS = "0xdac17f958d2ee523a2206206994597c13d831ec7";
const USDT_CONTRACT_USDCADDRESS = "0x7f5c764cbc14f9669b88837ca1490cca17c31607";
const USDT_CONTRACT_BSCADDRESS = "0x55d398326f99059fF775485246999027B3197955";

// 语言列表
const LANG_LIST = {
  'en': { id: "en", img: require("@/assets/image/usd.png"), title: "English", color: "yellow" },
  'jp': { id: "jp", img: require("@/assets/image/japan.png"), title: "日本語", color: "" }, // Japanese
  'kor': { id: "kor", img: require("@/assets/image/korean.png"), title: "한국어", color: "" }, // Korean
  'fr': { id: "fr", img: require("@/assets/image/fr.png"), title: "Français", color: "" }, // French
  'pu': { id: "pu", img: require("@/assets/image/pu.png"), title: "Português", color: "" }, // 葡萄牙语
  'it': { id: "it", img: require("@/assets/image/it.png"), title: "Italian", color: "" }, // 意大利语
  'ge': { id: "ge", img: require("@/assets/image/ge.png"), title: "Deutsch", color: "" }, // 德语
  'zh': { id: "zh", img: require("@/assets/image/china.svg"), title: "中文(简体)", color: "" }, // Chinese (Simplified)
  'cn': { id: "cn", img: require("@/assets/image/tw.png"), title: "中文(繁体)", color: "" }, // Chinese (Traditional)
  'sp': { id: "sp", img: require("@/assets/image/spain.png"), title: "Español", color: "" }, // Spanish 西班牙语
  'ru': { id: "ru", img: require("@/assets/image/russian.png"), title: "Русский", color: "" }, // Russian
  'tu': { id: "tu", img: require("@/assets/image/tu.png"), title: "Türkçe", color: "" }, // Turkish 土耳其语
  //'fa': { id: "fa", img: require("@/assets/image/fa.png"), title: "فارسی", color: "" }, // 波斯语
  //'ta': { id: "ta", img: require("@/assets/image/ta.png"), title: "தமிழ்", color: "" }, // 泰米尔语
};


// 链列表
const CHAIN_LIST = {
  "bsc": { id: "bsc", img: require("@/assets/image/bsc.png"), title: "BSC", type: "2", color: "yellow" },
  "erc": { id: "erc", img: require("@/assets/image/erc.png"), title: "ETH", type: "0", color: "" },
};


// 测试用/样本数据
const DEMO = {
  mining: {
    // 流动性挖矿金额级别--利息对照表
    liquidity_earning: [
      { level: 1, amount: 1000, earnings: 1.00 },
      { level: 2, amount: 5000, earnings: 1.40 },
      { level: 3, amount: 10000, earnings: 1.80 },
      { level: 4, amount: 30000, earnings: 2.60 },
      { level: 5, amount: 50000, earnings: 3.80 },
      { level: 6, amount: 80000, earnings: 4.80 },
      { level: 7, amount: 150000, earnings: 5.80 },
      { level: 8, amount: 300000, earnings: 6.80 },
      { level: 9, amount: 500000, earnings: 9.80 }
    ],
    // 质押挖矿金额级别--利息对照表
    stake_earning: [
      { level: 1, amount: 1000, earnings: 2.00 },
      { level: 2, amount: 5000, earnings: 2.80 },
      { level: 3, amount: 10000, earnings: 3.80 },
      { level: 4, amount: 30000, earnings: 4.80 },
      { level: 5, amount: 50000, earnings: 5.80 },
      { level: 6, amount: 80000, earnings: 6.80 },
      { level: 7, amount: 150000, earnings: 7.80 },
      { level: 8, amount: 300000, earnings: 8.80 },
      { level: 9, amount: 500000, earnings: 10.80 }
    ]
  },
  stake_pool: [
    { period: 3 },
    { period: 15 },
    { period: 30 },
    { period: 60 },
  ],
  mining_info: {
    liquidity_pool: 6369521371,
    liquidity_node: 534576104,
    total_revenue: 187652337,
    participants: 81293360,
    pre_sale_winrate: 9.1578,
    staking_pool: 287684135
  },
  banner: [
    { image: "./banner.png" },
    { image: "./banner.png" }
  ]

}

// 导出常量
export default {
  BASE_URL,
  USDT_CONTRACT_ADDRESS,
  USDT_CONTRACT_TRCADDRESS,
  USDT_CONTRACT_ERCADDRESS,
  USDT_CONTRACT_USDCADDRESS,
  USDT_CONTRACT_BSCADDRESS,
  LANG_LIST,
  CHAIN_LIST,
  DEMO: DEMO,
};
