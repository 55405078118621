<template>
  <div class="container">
    <van-row>
      <!-- Banner Section -->
      <van-col span="24">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.image" alt="" class="banner-image" />
          </van-swipe-item>
        </van-swipe>
      </van-col>
    </van-row>

    <van-row class="content-row">
      <van-col span="24" class="title-col">{{ $t("Next-gen blockchain intelligent staking") }}</van-col>

      <van-col span="24" class="icon-text-row">
        <div class="icon-text">
          <img :src="require('@/assets/image/list1.png')" alt="" class="icon-img" />
          <span class="icon-text-span">{{ $t("No need to transfer funds") }}</span>
        </div>
      </van-col>

      <van-col span="24" class="icon-text-row">
        <div class="icon-text">
          <img :src="require('@/assets/image/list2.png')" alt="" class="icon-img" />
          <span class="icon-text-span">{{ $t("Stable Income") }}</span>
        </div>
      </van-col>

      <van-col span="24" class="icon-text-row">
        <div class="icon-text">
          <img :src="require('@/assets/image/list3.png')" alt="" class="icon-img" />
          <span class="icon-text-span">{{ $t("Free Exit") }}</span>
        </div>
      </van-col>

      <van-col span="24" class="footer-col">
        {{ $t("Committed to building...") }}
        <span class="highlight-text">DEX,IMO</span> & <span class="highlight-text">DAO</span>
      </van-col>
    </van-row>

  </div>
  <div class="project-features">
    <van-row>
      <van-col span="24" class="title">{{ $t("Project Features") }}</van-col>

      <van-col span="24" class="feature-item">
        <van-row>
          <van-col span="5">
            <img :src="require('@/assets/image/row1.png')" alt="" class="feature-icon" />
          </van-col>
          <van-col span="19">
            <van-row align="center">
              <van-col span="24" class="feature-title">{{ $t("Safe and Reliable") }}</van-col>
              <van-col span="24" class="feature-desc">{{ $t("No need to transfer assets...") }}</van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>

      <van-col span="24" class="feature-item">
        <van-row>
          <van-col span="5">
            <img :src="require('@/assets/image/row2.png')" alt="" class="feature-icon" />
          </van-col>
          <van-col span="19">
            <van-row align="center">
              <van-col span="24" class="feature-title">{{ $t("Professional Stability") }}</van-col>
              <van-col span="24" class="feature-desc">{{ $t("Professional team, stable operation year-round") }}</van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>

      <van-col span="24" class="feature-item">
        <van-row>
          <van-col span="5">
            <img :src="require('@/assets/image/row3.png')" alt="" class="feature-icon" />
          </van-col>
          <van-col span="19">
            <van-row align="center">
              <van-col span="24" class="feature-title">{{ $t("Low Entry Threshold") }}</van-col>
              <van-col span="24" class="feature-desc">{{ $t("Share Node Mining Revenue") }}</van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>

    </van-row>
  </div>

  <div class="statement-container">
    <van-col span="24" style="font-size: 1.5rem">
      {{ $t("Revenue Distribution") }}
    </van-col>
    <div>
      <div class="defi-statement-title van-hairline--bottom">{{ $t("Liquidity Mining") }}
      </div>
      <van-row justify="center" class="defi-statement-head">
        <van-col span="8">{{ $t("Level") }}</van-col>
        <van-col span="8" class="center">{{ $t("Amount") }}</van-col>
        <van-col span="8" class="right">{{ $t("Earnings") }}</van-col>
      </van-row>
      <van-row class="defi-statement-list" v-for="(item, index) in walletBalanceList" :key="index">
        <van-col span="8">Vip {{ item.level }}</van-col>
        <van-col span="8" class="center">{{ item.amount }} USDT</van-col>
        <van-col span="8" class="right">{{ item.earnings }}%</van-col>
      </van-row>
    </div>
    <div>
      <div class="defi-statement-title van-hairline--bottom">{{ $t("Advanced Mining Pool") }}</div>
      <van-row justify="center" class="defi-statement-head">
        <van-col span="8">{{ $t("Level") }}</van-col>
        <van-col span="8" class="center">{{ $t("Amount") }}</van-col>
        <van-col span="8" class="right">{{ $t("Earnings") }}</van-col>
      </van-row>
      <van-row class="defi-statement-list" v-for="(item, index) in stakeAmountList" :key="index">
        <van-col span="8">Vip {{ item.level }}</van-col>
        <van-col span="8" class="center">{{ item.amount }} USDT</van-col>
        <van-col span="8" class="right">{{ item.earnings }}%</van-col>
      </van-row>
    </div>
  </div>

  <div class="partner-section">
    <van-row>
      <van-col span="24" class="title">{{ $t("Cooperation Platform") }}</van-col>
      <van-col span="24" class="partner-container">
        <van-row gutter="10" v-for="(row, index) in partnersChunks" :key="index" style="margin-top: 10px">
          <van-col v-for="partner in row" :key="partner.name" span="8">
            <div class="partner-box">
              <img :src="partner.img" alt="" class="partner-icon" />
              <span class="partner-name">{{ partner.name }}</span>
            </div>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>

  <div class="copy-right"> © 2024 All rights reserved </div>
  <div style="height: 90px"></div>
</template>

<script>
export default {
  name: "service",
  data() {
    return {
      levelList: [
        {
          level_name: "primary",
          min_balance: "30",
          min_income: "1.5",
        },
        {
          level_name: "intermediate",
          min_balance: "60day",
          min_income: "2",
        },
        {
          level_name: "senior",
          min_balance: "90day",
          min_income: "3",
        },
      ],
      bannerList: [{ image: require("../../public/photo.png") }, { image: require("../../public/photo.png") }],
      partners: [
        { name: "Binance", img: require('@/assets/image/biance.png') },
        { name: "FTX", img: require('@/assets/image/ftx.png') },
        { name: "KuCoin", img: require('@/assets/image/kucoin.png') },
        { name: "KraKen", img: require('@/assets/image/kraken.png') },
        { name: "Gate.io", img: require('@/assets/image/gate.io.png') },
        { name: "Upbit", img: require('@/assets/image/uopbit.png') },
        { name: "OkEx", img: require('@/assets/image/okex.png') },
        { name: "Bitfinex", img: require('@/assets/image/bitfinex.png') },
        { name: "Wazirx", img: require('@/assets/image/wazirx.png') },
        { name: "HuoBi", img: require('@/assets/image/huobi.svg') },
        { name: "Crypto.com", img: require('@/assets/image/crypto.com.png') },
        { name: "CoinBase", img: require('@/assets/image/coinbase.png') }
      ],
      walletBalanceList: [],
      stakeAmountList: []
    };
  },
  computed: {
    partnersChunks() {
      // 将合作伙伴数据分成每行3个的块
      const chunkSize = 3;
      return Array.from({ length: Math.ceil(this.partners.length / chunkSize) }, (v, i) =>
        this.partners.slice(i * chunkSize, i * chunkSize + chunkSize)
      );
    }
  },
  methods: {
    init: function () {
      this.$api.index.banner().then((res) => {
        if (res.status === 200) {
          this.bannerList = res.data
        }
      });
      this.$api.index.miningStatement().then((res) => {
        if (res.status === 200) {
          this.walletBalanceList = res.data.walletBalanceList,
            this.stakeAmountList = res.data.stakeAmountList
        }
      })
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.statement-container {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #212244;
  margin-top: 20px;
}

.defi-statement-title {
  margin-top: 28px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: rgb(255, 153, 0);
  font-size: 18px;
  font-weight: 600;
}

.defi-statement-head {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  color: rgb(112, 128, 179);
}

.defi-statement-list {
  line-height: 24px;
  font-size: 14px;
  color: rgb(112, 128, 179);
}

.container {
  width: 100%;
  padding: 15px;
  /* 30px */
  box-sizing: border-box;
  background-color: rgba(33, 34, 68, 1);
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.my-swipe .van-swipe-item {
  width: 100%;
  height: 200px;
}

.banner-image {
  width: 100%;
  height: 200px;
  border-radius: 15px;
  /* 20px */
}

.content-row {
  margin-top: 20px;
  padding: 0 15px;
}

.title-col {
  font-size: 1.5rem;
  font-weight: 500;
}

.icon-text-row {
  margin-top: 10px;
}

.icon-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon-img {
  width: 24px;
  height: 24px;
}

.icon-text-span {
  margin-left: 20px;
  box-sizing: border-box;
}

.footer-col {
  margin-top: 20px;
  color: rgba(112, 128, 179, 1);
}

.highlight-text {
  color: #ffffff;
}

.project-features {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #212244;
  margin-top: 20px;
}

.title {
  font-size: 1.5rem;
}

.feature-item {
  margin-top: 20px;
}

.feature-icon {
  width: 51px;
  height: 51px;
}

.feature-title {
  font-size: 16px;
}

.feature-desc {
  color: #7080b3;
  margin-top: 10px;
  box-sizing: border-box;
}

.partner-section {
  width: 100%;
  padding: 30px 10px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #212244;
  margin-top: 20px;
}

.partner-section .title {
  padding-left: 20px;
  font-size: 1.5rem;
}

.partner-container {
  margin-top: 20px;
  color: #7080b3;
}

.partner-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: rgba(44, 44, 83, 1);
  border-radius: 10px;
}

.partner-icon {
  width: 20px;
  height: 20px;
}

.partner-name {
  margin-left: 10px;
}

.copy-right {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  font-size: 0.875rem;
}
</style>
