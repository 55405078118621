<template>
  <div class="container">
    <div class="image-container">
      <img :src="require('@/assets/image/invite.png')" alt="" class="invite-image" />
      <div class="invite-info">
        <van-row gutter="20">
          <van-col span="12">
            <van-row>
              <van-col span="24" class="invite-code">
                {{ invite_code }}
              </van-col>
              <van-col class="description" span="24">
                {{ $t("CUSDT next-gen mobile node mining") }}
              </van-col>
            </van-row>
          </van-col>
          <van-col span="12" class="qr-code-container">
            <img :src="qrUrl" alt="" class="qr-code" />
          </van-col>
        </van-row>
      </div>
    </div>
  </div>

  <div class="rewards-container">
    <van-row @click="copyLink">
      <van-col span="24" class="centered">
        <img :src="require('@/assets/image/copy.png')" alt="" class="copy-icon" />
      </van-col>
      <van-col span="24" class="centered">
        {{ $t("Copy Link") }}
      </van-col>
    </van-row>

    <van-row class="rewards-details">
      <van-col span="24" class="rewards-title">
        {{ $t("Invitation Rewards") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("Invitation Rewards 1...") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("Invitation Rewards 2...") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("Invitation Rewards 3...") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("Example") }}：
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("Invitation Rewards 4...") }}
      </van-col>
    </van-row>
  </div>

  <div class="spacer"></div>

  <input v-model="code" ref="input" />
</template>

<script>
const protocol = window.location.protocol; // 获取协议（http: 或 https:）
const currentUrl = `${protocol}//${window.location.host}${process.env.VUE_APP_PUBLIC_PATH}`;

export default {
  name: "invite",
  data() {
    return {
      baseURL: currentUrl,
      qrUrl: "",
      text: "",
      invite_code: process.env.VUE_APP_BASE_URL + "",
      tronweb: "",
      code: "",
      dataid: "",
    };
  },
  methods: {
    copyLink() {
      this.$refs.input.select();
      document.execCommand("copy");
      return this.$toast(this.$t("Successful"));
    }
  },
  mounted() {
    this.$api.users.info().then(res=>{
      if (res.data.code == 1) {
          const userInfo = res.data.data;

          this.dataid = userInfo.id;
          this.code = this.baseURL + `#/?invite_code=${userInfo.id}`;
          this.invite_code = this.code;
          
          const Qrcode = require("qrcode");
          Qrcode.toDataURL(this.code, (err, url) => {
            console.log("Qrcode.toDataURL", url)
            this.qrUrl = url;
          });
      }
    })
  },
};
</script>

<style scoped>
.container {
  padding: 30px;
  box-sizing: border-box;
}

.image-container {
  width: 100%;
}

.invite-image {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.invite-info {
  width: 100%;
  background-color: rgb(33, 34, 68);
  padding: 30px 20px;
  box-sizing: border-box;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.invite-code {
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  word-wrap: break-word;  
  overflow-wrap: break-word;
  white-space: normal; 
}

.description {
  margin-top: 20px;
  color: #7080b3;
}

.qr-code-container {
  text-align: right;
}

.qr-code {
  width: 100px;
  height: 100px;
}

.rewards-container {
  background-color: rgb(33, 34, 68);
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
}

.centered {
  text-align: center;
}

.copy-icon {
  width: 50px;
  height: 50px;
}

.rewards-details {
  margin-top: 30px;
}

.rewards-title {
  font-size: 1.125rem;
}

.rewards-description {
  margin-top: 15px;
  color: #7080b3;
}

.spacer {
  height: 90px;
}
</style>
