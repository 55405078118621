// /config/util/tools.js

export function isAbsoluteURL(url) {
    // 正则表达式检测以协议开头的字符串
    const pattern = new RegExp('^(https?:|ftp:|file:)//');
    return pattern.test(url);
}

/**
 * 格式化浮点数val，最多保留8位小数
 * @param {} val 
 * @param {*} len 
 * @returns 
 */
export const floatFormat = (val, len = 8) => {
    return parseFloat(parseFloat(val || 0).toFixed(len));
};

/**
 * 格式化钱包地址
 * @param {*} addr 
 * @returns 
 */
export const maskAddress = (addr) => {
    if (!addr) return "****";
    const prefix = addr.slice(0, 4);
    const suffix = addr.slice(-4);
    return `${prefix}****${suffix}`;
};