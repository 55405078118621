<template>
    <!-- 利息提现弹窗 -->
    <div class="withdraw-dialog">
        <van-dialog v-bind:show="isShow" show-cancel-button :title="$t('Withdrawal')"
            :before-close="handleWithdrawConfirm" :confirmButtonText="$t('Confirm')" :cancelButtonText="$t('Cancel')"
            @close="emitClose">
            <div>
                <van-row class="withdraw-dialog__title van-hairline--bottom">
                    <van-col class="text-sm">
                        {{ $t('Available Interest Balance') }}: {{ $getters.account.interest_balance }}
                    </van-col>
                </van-row>
                <van-field v-model="withdrawAmount" type="number" size="large" :max="$getters.account.interest_balance"
                    :error-message="withdrawMsg" :placeholder="$t('Please enter the withdrawal amount')">
                    <template #button>
                        <van-button @click="withdrawInsertMax" size="small" round hairline :plain="true" type="">
                            {{ $t('All') }}
                        </van-button>
                    </template>
                </van-field>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "DfWithdraw",
    props: {
        isShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            withdrawAmount: '',
            withdrawMsg: '',
        };
    },
    computed: {
        ...mapGetters({
            account: 'account' // 从 Vuex 获取账户信息
        })
    },
    methods: {
        handleWithdrawConfirm(action) {
            if (action === 'confirm') {
                if (this.withdrawAmount > this.$getters.account.interest_balance) {
                    this.withdrawMsg = this.$t("Withdrawal amount exceeds the balance");
                    return false;
                } else if (!this.withdrawAmount || isNaN(this.withdrawAmount) || this.withdrawAmount <= 0) {
                    this.withdrawMsg = this.$t("Invalid Input");
                    return false;
                }

                return new Promise((resolve, reject) => {
                    this.$api.users.withdrawal({ withdraw_amount: this.withdrawAmount })
                        .then(res => {
                            if (res.status === 200) {
                                let result = res.data;
                                if (result.code === 1) {
                                    this.$toast(this.$t("Withdrawal application successful"));
                                    this.$api.users.info();//刷新用户数据
                                } else if (result.code === 0) {
                                    this.$toast(this.$t("Please connect your wallet first"));
                                } else if (result.code === 110) {
                                    this.$toast(this.$t("Withdrawal amount must be at least") + " " + result.data.minWithdrawAmount);
                                } else if (result.code === 120) {
                                    this.$toast(this.$t("Insufficient balance"));
                                }
                                this.emitClose();
                                resolve(true);
                            } else {
                                this.withdrawMsg = this.$t("Withdrawal failed");
                                reject(false);
                            }
                        })
                        .catch(error => {
                            console.error("API Error:", error);
                            this.withdrawMsg = this.$t("Withdrawal failed");
                            reject(false);
                        });
                });
            }
            this.emitClose();
            return true;
        },
        withdrawInsertMax() {
            this.withdrawAmount = this.$getters.account.interest_balance;
        },
        emitClose() {
            // 关闭弹窗并通知父组件
            this.$emit('update:isShow', false);
        }
    },
    watch: {
        isShow(newVal) {
            if (newVal) {
                this.withdrawAmount = '';
                this.withdrawMsg = '';
                // 交给父页面处理了
                // this.$toast.loading({
                //     message: this.$t("Loading..."),
                //     forbidClick: true,
                //     duration: 2000 // Toast will remain until cleared manually
                // });
                // this.$api.users.info().then(res => {
                //     this.$toast.clear();
                //     this.withdrawAmount = '';
                // });
            }
        }
    }
};
</script>

<style scoped>
.withdraw-dialog {
    --van-dialog-header-line-height: 60px;
    --van-dialog-header-padding-top: 0;
    --van-dialog-button-height: 60px;
    --van-field-input-text-color: #FFF;
    --van-dialog-confirm-button-text-color: #FFF;
    --van-button-default-color: #FFF;
    --van-field-error-message-color: #333;
    --van-cell-background-color: rgba(0, 0, 0, 0);
    --van-cell-font-size: 16px;

    --van-button-default-background-color: rgba(0, 0, 0, 0);

    --van-dialog-background-color: linear-gradient(90deg, var(--tw-gradient-stops));
    --tw-gradient-stops: #0993ec, #f338c3;

    color: #FFF !important;
}

.withdraw-dialog__title {
    padding: 6px var(--van-cell-horizontal-padding);
    display: flex;
    justify-content: flex-end;
}
</style>