<template>
  <div class="container">
    <van-row>
      <!-- Banner Section -->
      <van-col span="24">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.image" alt="" class="banner-image" />
          </van-swipe-item>
        </van-swipe>
      </van-col>

      <!-- Announcement Section -->
      <van-col span="24" class="announcement-section">
        <van-loading v-if="announcemenLoading" type="spinner" size="17" />
        <van-swipe v-else vertical :autoplay="5000" style="height:30px;" indicator-color="white"
          :show-indicators="false">
          <van-swipe-item v-for="item in gongao" :key="item.id">
            <div class="ellipsis-text">{{ item.biaoti }}: {{ item.neirong }}</div>
          </van-swipe-item>
        </van-swipe>
      </van-col>

      <!-- Address -->
      <van-col span="24" class="address-box">
        <van-row justify="space-between">
          <van-col>{{ $t("Address") }}</van-col>
          <van-col>{{ $getters.account.address_masked }}</van-col>
        </van-row>
      </van-col>

      <!-- Balance & Pledge -->
      <van-col span="24">
        <van-row gutter="30" justify="space-around" class="bottom-line">
          <van-col span="12" class="content-box-1">
            <div class="color-blue">{{ $t("Total Balance") }}</div>
            <df-number-resize class="text-bold" :number="$getters.account.balance"></df-number-resize>
          </van-col>
          <van-col span="12" class="content-box-1">
            <div class="color-blue">{{ $t("Pledge Amount") }}</div>
            <div class="text-lg text-bold">{{ $getters.account.pledge_amount }}</div>
          </van-col>
        </van-row>
      </van-col>

      <!-- Interest, VIP & Withdrawing Section -->
      <van-col span="24">
        <van-row gutter="30" justify="space-around" class="bottom-line">
          <van-col span="8" class="content-box-2">
            <div class="color-blue">{{ $t("Interest Balance") }}</div>
            <div class="text-bold">{{ $getters.account.interest_balance }}</div>
          </van-col>
          <van-col span="8" class="content-box-2">
            <div class="color-blue">{{ $t("VIP Level") }}</div>
            <div class="text-bold">{{ $getters.account.vip_level }}</div>
          </van-col>
          <van-col span="8" class="content-box-2">
            <div class="color-blue">{{ $t("Withdrawing") }}</div>
            <div class="text-bold">{{ $getters.account.interest_frozen }}</div>
          </van-col>
        </van-row>
      </van-col>

      <!-- Daily Interest -->
      <van-col span="24">
        <van-row gutter="10" class="daily-interest bottom-line" justify="space-between">
          <van-col span="12" class="content-box-3">
            <div class="color-blue">{{ $t("Daily Interest") }}</div>
            <div class="text-bold">≈ {{ $getters.account.daily_interest }}</div>
          </van-col>
          <van-col span="12" class="content-box-3">
            <div class="color-blue">{{ $t("Total Interest") }}</div>
            <div class="text-bold">{{ $getters.account.total_income }}</div>
          </van-col>
        </van-row>
        <van-row justify="space-between">
          <van-col span="12">
            <van-button class="bt-def btn-interest" @click="withdrawal">{{ $t("Withdrawal") }}</van-button>
          </van-col>
          <van-col span="12" class="right">
            <van-button class="bt-def btn-interest" @click="walletConnect">{{ $t("Participate") }}</van-button>
          </van-col>
        </van-row>
      </van-col>

    </van-row>
  </div>

  <div class="advance-pool ">
    <van-row>
      <!-- Advanced Mining Pool -->
      <van-col span="24" class="center text-nm text-bold">{{ $t("Advanced Mining Pool") }}</van-col>
      <van-col span="24">
        <van-row class="stake-list" justify="space-between">
          <div v-for="(item, index) in stakePool" :key="index" span="6" @click="stakeCheck(index)">
            <span class="stake-list__tag" :class="{ 'stake-list__tag-active': index == stakeIndex }">{{ item.period }}
              {{
                $t("Day") }}</span>
          </div>
        </van-row>
        <van-row justify="space-between">
          <van-col span="12">
            <van-button class="bt-def btn-advacne" @click="apply">{{ $t("Apply") }}</van-button>
          </van-col>
          <van-col span="12" class="right">
            <van-button class="bt-def btn-advacne" @click="unpledge">{{ $t("Unpledge") }}</van-button>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>

  <!-- Bottom Info Section -->
  <div class="bottom-info">
    <div class="info-box">
      <van-row>
        <van-col span="12">{{ $t("Liquidity Node") }}
          <van-icon name="star" class="star-icon" />
        </van-col>
        <van-col span="12" class="right-text">
          <df-count-up :endVal="miningInfo.liquidity_node" />
        </van-col>
      </van-row>
    </div>

    <div class="statistics-box">
      <van-row>
        <van-col span="8" class="center-text">
          <img :src="require('../assets/image/1.png')" class="stat-icon" />
        </van-col>
        <van-col span="8" class="center-text">
          <img :src="require('../assets/image/2.png')" class="stat-icon" />
        </van-col>
        <van-col span="8" class="center-text">
          <img :src="require('../assets/image/3.png')" class="stat-icon" />
        </van-col>
      </van-row>
      <van-row class="stat-text">
        <van-col span="8">{{ $t("Liquidity Pool") }}</van-col>
        <van-col span="8" class="center-text">{{ $t("Participants") }}</van-col>
        <van-col span="8" class="center-text">{{ $t("Income") }}</van-col>
      </van-row>
      <van-row class="stat-values">
        <van-col span="8"><df-count-up :endVal="miningInfo.liquidity_pool" /></van-col>
        <van-col span="8" class="center-text"><df-count-up :endVal="miningInfo.participants" /></van-col>
        <van-col span="8" class="center-text"><df-count-up :endVal="miningInfo.total_revenue" /></van-col>
      </van-row>
    </div>

    <!-- Additional Info Section -->
    <div class="extra-info">
      <van-row gutter="30" justify="space-between">
        <van-col span="11" class="extra-info-box">
          <van-row>
            <van-col span="24" class="center-text">
              <img :src="require('../assets/image/4.png')" class="extra-info-icon" />
            </van-col>
            <van-col span="24" class="extra-info-text">{{ $t("Pre-sale Winning Rate") }}</van-col>
            <van-col span="24" class="extra-info-value">
              <df-count-up :endVal="miningInfo.pre_sale_winrate" :decimals="4" />%
            </van-col>
          </van-row>
        </van-col>
        <van-col span="11" class="extra-info-box">
          <van-row>
            <van-col span="24" class="center-text">
              <img :src="require('../assets/image/5.png')" class="extra-info-icon" />
            </van-col>
            <van-col span="24" class="extra-info-text">{{ $t("Staking Pool") }}</van-col>
            <van-col span="24" class="extra-info-value">
              <df-count-up :endVal="miningInfo.staking_pool" />
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
  </div>

  <!-- FAQ Section -->
  <div class="faq-section">
    <van-row>
      <van-col span="24" class="faq-title">{{ $t("FAQ") }}</van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-collapse v-model="activeNames" accordion :border="false">
          <van-collapse-item title="Why do we need to pay miners to cash out profits?" name="1" :border="false">
            Because in the process of participating in mining, the customer only
            counts the name, and the miner's fee has been paid by the miners.
            The revenue generated is actually in a contract pool, and only the
            miner's fee can be exchanged for revenue, which only needs to be
            paid once.
          </van-collapse-item>
          <van-collapse-item title="How to withdraw profit?" name="2" :border="false">
            After obtaining USD, your wallet must have a small amount of ETH as
            the transaction fee. You only need to pay once, and the USDT
            exchange will be completed within one legal working day.
          </van-collapse-item>
          <van-collapse-item title="How do I participate?" name="3" :border="false">
            1. The wallet is on the chain, and the wallet comes with a Dapp
            browser 2. Connect ERC20 to USDT and deposit into your wallet 3.
            Enter the official website through the wallet browser 4. Your wallet
            must have more than 30 USD ETH as miner fee to participate in this
            project. 5. The platform donates 1400G of energy every day, and
            consumes 700G of energy for one participation
          </van-collapse-item>
          <van-collapse-item title="How long does it take to participate in mining?" name="4" :border="false">
            The time cannot be calculated. Generally speaking, it will be
            completed within 4 hours. If other uncertain reasons are
            encountered, the time may be extended.
          </van-collapse-item>
          <van-collapse-item title="Why do we need to pay miners' fees for cashing out profits?" name="5"
            :border="false">
            Because in this process of participating in mining, the customer
            only counts the name, and the miners' fees generated have been paid
            by the miners in a unified manner. The revenue generated is actually
            in a contract pool, and only the payment of the miner's fee can be
            exchanged for the revenue.
          </van-collapse-item>
        </van-collapse>
      </van-col>
    </van-row>
  </div>

  <div style="height: 90px"></div>

  <!-- 利息提现弹窗 -->
  <df-withdraw :isShow="withdrawShow" @update:isShow="withdrawShow = $event" />
</template>


<script>
import walletMixin from '@/mixin/wallet';
import { mapState } from "vuex";

export default {
  name: "HomeView",
  mixins: [walletMixin],
  data() {
    return {
      baseURL: this.$config.BASE_URL,
      activeNames: 1,
      bannerList: [{ image: require("../../public/photo.png") }, { image: require("../../public/photo.png") }],

      gongao: [{ biaoti: '-' }],
      announcemenLoading: true,
      miningInfo: {
        liquidity_pool: 0,
        liquidity_node: 0,
        total_revenue: 0,
        participants: 0,
        pre_sale_winrate: 0,
        staking_pool: 0
      },
      stakePool: {},
      stakeIndex: 0,

      withdrawShow: false,
    };
  },
  computed: {
    ...mapState(['address', 'chain', 'lang', 'invite_code', 'bsc', 'eth', 'kefu'])
  },
  methods: {
    init: function () {
      this.$api.index.announcement().then(res => {
        if (res.status === 200) {
          this.gongao = res.data;
        }
        this.announcemenLoading = false;
      })
      this.$api.index.banner().then((res) => {
        if (res.status === 200) {
          this.bannerList = res.data
        }
      });
      this.$api.index.miningSysInfo().then((res) => {
        if (res.status === 200) {
          this.miningInfo = res.data
        }
      });

      this.$api.index.stakePool().then((res) => {
        if (res.status === 200) {
          this.stakePool = res.data
        }
      });
    },
    stakeCheck: function (index) {
      this.stakeIndex = index;
    },
    //质押申请
    apply: function () {
      let params = {
        chain: this.chain,
        stake_amount: this.$getters.account.balance,
        period: this.stakePool[this.stakeIndex].period
      };
      this.$api.users.apply(params).then(res => {
        console.log(res)
        if (res.status == 200) {
          let result = res.data;
          if (result.code === 1) {
            this.$toast(this.$t("Application successful"));
          } else if (result.code === 0) {
            this.$toast(this.$t("Please connect your wallet first"));
          } else if (result.code === 110) {
            this.$toast(this.$t("Application submitted, awaiting review"));
          } else if (result.code === 120) {
            this.$toast(this.$t("Staking amount must be at least") + " " + result.data.minStakeAmount ?? 1000);
          } else if (result.code === 130) {
            this.$toast(this.$t("Invalid staking period"));
          } else if (result.code === 140) {
            this.$toast(this.$t("Unsuccessful"));
          }
        }

      })
    },
    //撤回质押申请
    unpledge: function () {
      this.$api.users.unpledge().then(res => {
        console.log(res)
        if (res.status == 200) {
          let result = res.data;
          if (result.code === 1) {
            this.$toast(this.$t("Cancellation successful"));
          } else if (result.code === 0) {
            this.$toast(this.$t("Please connect your wallet first"));
          } else if (result.code === 110) {
            this.$toast(this.$t("Staking application not found"));
          } else if (result.code === 200) {
            this.$toast(this.$t("Cancellation request submitted, awaiting review"));
          } else if (result.code === 140) {
            this.$toast(this.$t("Unsuccessful"));
          }
        }
      })
    },
    withdrawal: function () {
      this.withdrawShow = true;
    },
  },
  watch: {},
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.faq-section {
  --van-cell-background-color: #161730;
  --van-cell-text-color: #FFF;
  --van-cell-active-color: #161730;
  --van-collapse-item-content-background-color: #212244,
}

.withdraw-dialog {
  --van-dialog-header-line-height: 60px;
  --van-dialog-header-padding-top: 0;
  --van-dialog-button-height: 60px;
  --van-field-input-text-color: #FFF;
  --van-dialog-confirm-button-text-color: #FFF;
  --van-button-default-color: #FFF;
  --van-field-error-message-color: #333;
  --van-cell-font-size: 16px;

  --van-button-default-background-color: rgba(0, 0, 0, 0);

  --van-dialog-background-color: linear-gradient(90deg, var(--tw-gradient-stops));
  --tw-gradient-stops: #0993ec, #f338c3;

  color: #FFF !important;
}

.withdraw-dialog__title {
  padding: 6px var(--van-cell-horizontal-padding);
  display: flex;
  justify-content: flex-end
}

.item_1 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* margin: auto 0; */
}

.img_1 {
  /* width:50%; */
  align-items: center;
}

.img_1 span {
  margin-left: 1rem;
  margin-right: 0.75rem;
}

.mydunhuan {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(34, 32, 32, 0.5);
}

.myjl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 40%;
  background-color: #fff;
  border-radius: 20px;
  background-color: rgba(34, 32, 32, 0.8);
  border-radius: 20px !important;
  /* border: 1px solid #fff; */
  overflow-y: auto;
  -ms-overflow-style: none;
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), #f338c3, rgba(9, 147, 236, 0);
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
  --tw-gradient-to: #f338c3;
  background-image: linear-gradient(90deg, var(--tw-gradient-stops));
}

.my_dhjl {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.my_dhjl h2 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 18px;
  margin-left: 10px;
}

.my_dhjl span {
  /* margin-right: 10px; */
  margin-left: auto;
  padding: 20px;
}

.my-swipe .van-swipe-item {
  width: 100%;
  height: 200px;
}

.van-cell {
  background: none;
}

.sybutton {
  width: 5rem;
  height: 3rem;
  background-color: #3a3b58;
  border-radius: 1rem;
  border: none;
  /* font-size: 16px; */
  font-weight: 700;
  box-shadow: #312d68 0px 1px 4px;
  margin-bottom: 1rem;
}

.container {
  width: 100%;
  padding: 15px 15px 30px;
  /* 30px */
  box-sizing: border-box;
  background-color: rgba(33, 34, 68, 1);
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.advance-pool {
  width: 100%;
  padding: 30px 15px;
  margin-top: 30px;
  box-sizing: border-box;
  background-color: rgba(33, 34, 68, 1);
  border-radius: 1.5rem;
}

.stake-list {
  margin: 30px 0;
}

.stake-list__tag {
  display: block;
  padding: 10px 10px;
  width: 50px;
  border: 1px solid rgba(133, 131, 201, 1);
  border-radius: 30px;
  text-align: center;
}

.stake-list__tag-active {
  background: rgba(133, 131, 201, 1);
}

.my-swipe {
  height: 200px;
}

.banner-image {
  width: 100%;
  height: 200px;
  border-radius: 15px;
  /* 20px */
}

.announcement-section {
  margin-top: 10px;
  padding: 0 15px;
  height: 17px;
  box-sizing: border-box;
}

.address-box{
  margin-top: 10px;
  padding: 15px;
  border-radius: 15px;
  background: rgb(22, 23, 48, .65);
}

.content-box-1 {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  line-height: 34px;
}

.content-box-2 {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 24px;
}

.content-box-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 28px;
}

.daily-interest {
  margin: 10px 0;
  padding: 10px 0;
  border-radius: 15px;
  background: rgb(22, 23, 48, .65);
}

.balance-section {
  margin-top: 30px;
  padding: 0 15px;
  box-sizing: border-box
}

.balance-text {
  font-size: 1.875rem;
  font-weight: 550;
  color: #ffffff;

  display: flex;
  align-items: center;
}

.token-name {
  color: #7080b3;
}

.btn-interest {
  margin-top: 5px;
  min-width: 80%;
}

.btn-advacne {
  margin-top: 5px;
  min-width: 80%;
}

.address-section {
  color: #ffffff;
  font-size: 0.8125rem;

  margin-top: 10px;
  padding: 20px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #2c2e55;
}

.address-display {
  display: flex;
  justify-content: flex-end;
}

.income-info-section {
  margin-top: 10px;
  padding: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #2c2e55;
}

.income-header {
  color: rgba(133, 131, 201, 1);
  font-size: 0.8125rem;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.income-values {
  margin-top: 20px;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 0.8125rem;
}

.staking-plan {
  margin-top: 20px;
  background-color: rgb(22 23 48);
  border-radius: 25px;
  box-shadow: 0 8px 24px rgb(212 212 212 / 37%);
  border-block: 0px solid rgb(228, 183, 183);
  padding: 20px;
}

.plan-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}

.plan-header>div {
  font-size: 14px;
  background-color: #5e4aca;

  padding: .25rem;
  border-radius: .5rem;
  margin-right: 10px;
}

.plan-header>span {
  display: block;
  color: #64657c;
  font-size: 18px;
  font-weight: bold;
}

.plan-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}

.plan-details>div {
  width: 50%;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plan-cycle,
.plan-rate {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.plan-rate {
  color: red;
  /* rgb(0, 178, 107) */
}

.plan-button-wrapper {
  text-align: center;
}

.sybutton {
  width: 5rem;
  height: 3rem;
  background-color: #3a3b58;
  border-radius: 1rem;
  border: none;
  font-weight: 700;
  box-shadow: 0 1px 4px #312d68;
  margin-bottom: 1rem;
}

.bottom-info {
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%
}

.info-box,
.statistics-box {
  width: 100%;
  background-color: rgba(33, 34, 68, 1);
  border-radius: 15px;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
}

.statistics-box {
  padding: 20px 0;
  text-align: center;
}

.star-icon {
  color: #ffd700;
  font-size: 15px
}

.stat-icon {
  width: 50px;
  height: 50px;
}

.stat-text {
  margin-top: 10px;
  font-size: 14px;
  color: rgb(112, 128, 179);
}

.stat-values {
  margin-top: 7px;
  font-size: 14px;
  text-align: center;
}

.extra-info {
  margin-top: 20px;
}

.extra-info-box {
  background-color: rgba(33, 34, 68, 1);
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
}

.extra-info-icon {
  width: 50px;
  height: 50px;
}

.extra-info-text {
  margin-top: 5px;
  font-size: 14px;
  color: rgb(112, 128, 179);
  white-space: nowrap;
}

.extra-info-value {
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.faq-section {
  min-height: 400px;
  width: 100%;
  border-radius: 30px;
  padding: 30px;
  margin-top: 30px;
  box-sizing: border-box;
  background: rgb(33, 34, 68)
}

.faq-title {
  font-size: 1.125rem
}

.van-collapse-item__title {
  font-size: 16px;
  font-weight: bold;
}

.van-collapse-item {
  margin-bottom: 10px;
}

.bottom-line {
  border-bottom: 1px solid #2c2e55;
}
</style>
