<template>
  <div class="count-up">{{ displayNumber }}</div>
</template>

<script>
export default {
  props: {
    startVal: {
      type: Number,
      default: 0, // 起始值
    },
    endVal: {
      type: [Number, String], // 允许 Number 和 String 类型
      default: 0,
      required: true, // 目标值
    },
    duration: {
      type: Number,
      default: 2000, // 动画持续时间（毫秒）
    },
    decimals: {
      type: Number,
      default: null, // 默认为 null，表示不显示小数
    },
  },
  data() {
    return {
      displayNumber: this.formatNumber(this.startVal), // 显示的当前数字
    };
  },
  computed: {
    endValFloat() {
      return parseFloat(this.endVal); // 转换为浮点数
    },
  },
  mounted() {
    this.startCountUp();
  },
  methods: {
    formatNumber(value) {
      // 如果 decimals 存在且大于 0，显示指定的小数位；否则显示整数
      return this.decimals !== null && this.decimals > 0
        ? value.toFixed(this.decimals)
        : Math.floor(value);
    },
    startCountUp() {
      const startTime = performance.now();
      const step = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / this.duration, 1);
        const currentValue = progress * (this.endValFloat - this.startVal) + this.startVal;

        // 根据是否指定 decimals 来决定显示整数还是小数
        this.displayNumber = this.formatNumber(currentValue);

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      };
      requestAnimationFrame(step);
    },
  },
};
</script>

<style scoped>
</style>
