<template>
  <div class="container">
    <van-row align="center">
      <van-col span="6">
        <img :src="require('@/assets/image/logo.png')" alt="" class="logo" />
      </van-col>
      <van-col span="18" class="account-address">
        {{ $getters.account.address_masked }}
      </van-col>
    </van-row>
  </div>

  <div class="content-padding">
    <van-row class="wallet-info">
      <van-col span="12"> {{ $t("Withdrawal Wallet") }} </van-col>
      <van-col span="12" class="wallet-balance">
        {{ $getters.account.address_masked }}
      </van-col>
    </van-row>
  </div>

  <div class="main-content-padding">
    <van-row>
      <van-col span="24" class="content-box">
        <van-row>
          <van-col span="24" class="content-box-padding">
            <van-row>
              <van-col span="24" class="center input-margin-top">
                <div>
                  <span>{{ $t("Available Interest Balance") }}</span>
                </div>
              </van-col>
              <van-col span="24" class="center input-margin-top">
                <div>
                  <span class="text-lg">{{ $getters.account.interest_balance }}</span>
                </div>
              </van-col>
            </van-row>
          </van-col>
          <van-col span="24" class="">
            <van-button class="bt-def" size="large" @click="withdrawal">{{ $t("Redeem Now") }}</van-button>
          </van-col>
          <van-col span="24" class="button-margin-top hide">
            <van-button class="bt-grey" size="large" @click="dhjl">{{ $t("Exchange History") }}</van-button>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>

  <div class="invite-section ">
    <div class="invite-box">
      <van-row>
        <van-col span="6" class="invite-img">
          <img :src="require('@/assets/image/invite1.png')" alt="" />
        </van-col>
        <van-col span="12">
          <van-row>
            <van-col span="24"> {{ $t("Invite") }} </van-col>
            <van-col span="24" class="invite-desc">
              {{ $t("Earn Commissions") }}
            </van-col>
          </van-row>
        </van-col>
        <van-col span="6">
          <van-button round class="invite-button" @click="$util.router.goto('/invite')">{{ $t("Invite") }}</van-button>
        </van-col>
      </van-row>
    </div>
  </div>

  <div class="earnings-section">
    <van-row>
      <van-col span="12" class="earnings-title">
        {{ $t("My Income") }}
      </van-col>
      <van-col span="12" class="text-align-right ">
        <!-- <van-button class="earnings-button" size="small" round @click="wdsy">{{ $t("View All") }}</van-button> -->
        <van-button class="earnings-button" size="small" round @click="myIncome">{{ $t("View All") }}</van-button>
      </van-col>
    </van-row>
    <div class="earnings-info">
      <van-row class="earnings-row">
        <van-col span="8" class="earnings-text">CUSDT</van-col>
        <van-col span="8" class="earnings-text center">CUSDT</van-col>
        <van-col span="8" class="earnings-text right">CUSDT</van-col>
      </van-row>
      <van-row class="earnings-row">
        <van-col span="8" class="ellipsis-text">{{ $getters.account.interest_balance }}</van-col>
        <van-col span="8" class="ellipsis-text center">{{ $getters.account.total_income }}</van-col>
        <van-col span="8" class="ellipsis-text right">{{ $getters.account.daily_interest }}</van-col>
      </van-row>
      <van-row class="earnings-row">
        <van-col span="8">{{ $t("Bal.") }}</van-col>
        <van-col span="8" class="center">{{ $t("Tot. Earn.") }}</van-col>
        <van-col span="8" class="right">{{ $t("Exp. Earn.") }}</van-col>
      </van-row>
    </div>
  </div>


  <div style="height: 90px"></div>
  <!-- 利息提现弹窗 -->
  <df-withdraw :isShow="withdrawShow" @update:isShow="withdrawShow = $event" />

  <!-- 兑换记录 -->
  <div class="mydunhuan" v-show="show">
    <div class="myjl">
      <div class="my_dhjl">
        <h2 class="mydhjltext">{{ $t("Exchange History") }}</h2>
        <span class="svg" @click="dhjl">
          <svg data-v-d82b0fde="" data-v-6b0d6abd="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" width="24" height="24">
            <path data-v-d82b0fde="" data-v-6b0d6abd="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M6 18L18 6M6 6l12 12"></path>
          </svg></span>
      </div>
      <div>
        <div class="myyj" v-for="item in finalRecorder" :key="item.id">
          <div class="my_bz">
            <div>{{ $t("Withdrawal Amount") }}:{{ item.price }}</div>
            <div style="color: rgb(209 227 255 / 55%) !important">
              {{ item.create_time }}
            </div>
          </div>
          <div class="my_lx">
            <span>{{ statusList[item.status] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 我的收益 -->
  <div class="mydunhuan" v-show="isshow">
    <div class="myjl">
      <div class="my_dhjl">
        <h2 class="mydhjltext">{{ $t("My Income") }}</h2>
        <span class="svg" @click="wdsy"><svg data-v-d82b0fde="" data-v-6b0d6abd="" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke="currentColor" width="24" height="24">
            <path data-v-d82b0fde="" data-v-6b0d6abd="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M6 18L18 6M6 6l12 12"></path>
          </svg></span>
      </div>
      <div>
        <div class="myyj" v-for="item in recorde" :key="item.id">
          <div class="my_bz">
            <div>+{{ item.money }}</div>
            <div style="color: rgb(209 227 255 / 55%) !important">
              {{ item.create_time }}
            </div>
          </div>
          <div class="my_lx">
            <span>{{ item.memo }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 派息记录Earning List -->
  <div class="mydunhuan" v-show="showEarningList">
    <div class="myjl">
      <div class="my_dhjl">
        <h2 class="mydhjltext">{{ $t("My Income") }}</h2>
        <span class="svg" @click="myIncome"><svg data-v-d82b0fde="" data-v-6b0d6abd="" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke="currentColor" width="24" height="24">
            <path data-v-d82b0fde="" data-v-6b0d6abd="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M6 18L18 6M6 6l12 12"></path>
          </svg></span>
      </div>
      <div>
        <div class="myyj" v-for="item in earningList" :key="item.id">
          <div class="my_bz">
            <div>+{{ item.earning_amount }}</div>
            <div style="color: rgb(209 227 255 / 55%) !important">
              {{ item.create_time }}
            </div>
          </div>
          <div class="my_lx">
            <span>{{ $t(item.type_text) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "my",
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      change_price: 0,
      token_number: 0,
      //address: "",
      show: false,
      isshow: false,
      money: "",
      finalRecorder: [],
      recorde: [],
      statusList: [
        this.$t("Unfinished"),
        this.$t("Completed"),
        this.$t("Rejected"),
      ],
      withdrawShow: false,
      showEarningList: false,
      EarningList: [],
    };
  },
  methods: {
    goto() {
      if (this.address == "") {
        this.$toast(this.$t("Please connect your wallet first"));
        return;
      }
      this.$router.push("/invite");
    },
    dhjl() {
      if (this.address == "") {
        this.$toast(this.$t("Please connect your wallet first"));
        return;
      }
      this.show = !this.show;
    },
    // 我的收益
    wdsy() {
      console.log(this.address)
      if (this.address == "") {
        this.$toast(this.$t("Please connect your wallet first"));
        return;
      }
      this.isshow = !this.isshow;
    },
    // 记录
    Record() {
      $.ajax({
        url: this.baseURL + "/api/index/finalRecorder",
        type: "post",
        data: {
          address: this.address,
        },
        success: (res) => {
          console.log(res);
          this.finalRecorder = res.data;
        },
      });
      //我的收益
      $.ajax({
        url: this.baseURL + "/api/index/recorder",
        type: "post",
        data: {
          address: this.address,
        },
        success: (res) => {
          this.recorde = res.data;
        },
      });
    },
    //兑换
    exchange() {
      if (this.address == "") {
        this.$toast(this.$t("Please connect your wallet first"));
        return;
      }
      if (this.money == "") {
        this.$toast(this.$t("Please enter the amount"));
        return;
      }
      $.ajax({
        url: this.baseURL + "/api/index/tiqu",
        type: "post",
        data: {
          address: this.address,
          money: this.money,
        },
        success: (res) => {
          this.$toast(res.msg);
          // location.reload();
        },
      });
    },
    ...mapActions(['updateAddr']),
    withdrawal: function () {
      this.$toast.loading({
        message: this.$t("Loading..."),
        forbidClick: true,
        duration: 2000 // Toast will remain until cleared manually
      });
      this.$api.users.info().then(res => {
        this.$toast.clear();
        this.withdrawAmount = '';
        this.withdrawShow = true;
      });

    },
    getEarningList: function(){
      this.$api.users.earningList().then(res=>{
        console.log(res)
        if (res.data.code == 1) {
          this.earningList = res.data.data;
        }
      })
    },
    myIncome() {
      if (this.address == "") {
        this.$toast(this.$t("Please connect your wallet first"));
        return;
      }
      console.log(this.earningList)
      this.showEarningList = !this.showEarningList;
    },
  },
  computed: {
    ...mapState({
      address: (state) => state.address,
    })
  },
  mounted() {
    console.log("my-mounted=", this.address);

    if (this.address) {
      //this.Record();
      this.getEarningList();
    }
  },
};
</script>




<style scoped>
.mydunhuan {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(34, 32, 32, 0.5);
}

.myjl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 40%;
  background-color: #fff;
  border-radius: 20px;
  background-color: rgba(34, 32, 32, 0.8);
  border-radius: 20px !important;
  /* border: 1px solid #fff; */
  overflow-y: auto;
  -ms-overflow-style: none;
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), #f338c3, rgba(9, 147, 236, 0);
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
  --tw-gradient-to: #f338c3;
  background-image: linear-gradient(90deg, var(--tw-gradient-stops));
}

.my_dhjl {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.my_dhjl h2 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 18px;
  margin-left: 10px;
}

.my_dhjl span {
  /* margin-right: 10px; */
  margin-left: auto;
  padding: 20px;
}

.myyj {
  height: 80px;
  background-color: #202231;
  margin: 10px;
  display: flex;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my_bz {
  width: 50%;
  /* height: 100%; */
}

.my_lx {
  width: 43%;
  display: flex;
  background: #23c68b33;
  color: #21c187;
  border-radius: 25px;
}

.my_lx span {
  /* margin-left: auto; */
  display: block;
  /* text-align: center; */
  margin: 10px auto;
  /* padding-right: 20px; */
}

.ellipsis-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #212244;
}

.logo {
  width: 64px;
  height: 64px;
  border-radius: 10px;
}

.account-address {
  font-size: 16px;
}

.content-padding {
  padding: 30px;
  box-sizing: border-box;
}

.wallet-info {
  padding: 15px;
  border-radius: 10px;
  background-color: #212244;
}

.wallet-balance {
  text-align: right;
}

.main-content-padding {
  width: 100%;
  padding: 0 30px 30px;
  box-sizing: border-box;
}

.content-box {
  padding: 10px 10px 30px 10px;
  box-sizing: border-box;
  background-color: #212244;
  border-radius: 10px;
  margin-top: 20px;
}

.content-box-padding {
  padding: 20px 10px;
  box-sizing: border-box;
}

.input-margin-top {
  margin-top: 20px;
}

.input-field {
  height: 31px;
  padding: 6px 10px;
  border-radius: 15px;
  box-sizing: border-box;
  background-color: #2c2c53;
  border: none;
}

.text-align-right {
  text-align: right;
}

.balance-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.balance-text {
  margin-left: 5px;
  line-height: 30px;
}

.button-margin-top {
  margin-top: 20px;
}

.invite-section {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
}

.invite-box {
  background: linear-gradient(318.46deg, #6b54eb 32.62%, #d080ff);
  border-radius: 20px;
  padding: 20px 0;
  box-sizing: border-box;
}

.invite-img {
  text-align: center;
}

.invite-img>img {
  height: 44px;
  width: 44px;
}

.invite-desc {
  margin-top: 15px;
}

.invite-button {
  color: #7080b3;
  background: #fff;
  border: none;
}

.earnings-section {
  background-color: rgb(33, 34, 68);
  width: 100%;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 30px;
}

.earnings-title {
  font-size: 1.25rem;
}

.earnings-button {
  color: #f90;
  padding: 10px 20px;
  background: rgb(55, 45, 61);
  border: none;
}

.earnings-info {
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #2c2c53;
}

.earnings-row {
  margin-top: 12px;
}

.earnings-text {
  font-size: 0.6875rem;
  color: #7080b3;
}

.team-section {
  width: 100%;
  margin-top: 30px;
  padding: 30px;
  box-sizing: border-box;
  background-color: rgb(33, 34, 68);
  border-radius: 20px;
}

.team-title {
  font-size: 1.25rem;
}

.team-button {
  color: #f90;
  padding: 10px 20px;
}

.team-info {
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #2c2c53;
}

.team-row {
  margin-top: 10px;
}

.team-text {
  font-size: 0.8rem;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
</style>
