// mutations.js
export const mutations = {  
    setAddr(state, newAddr) {  
        state.address = newAddr;  
    },
    setChain(state, chainIndex) {  
        state.chain = chainIndex;  
    },
    setLang(state, langIndex) {
        state.lang = langIndex;  
    },
    setUsers(state, Users) {  
        state.users = Users;  
    },
    setBalance(state, Balance) {  
        state.balance = Balance;  //注意这里设置的balance是前端的数据，用于和users.balance对比
    },
    /**
     * 通用方法：可以设置任意state：invite_code, kefu, bsc, eth, chain, token
     * store.commit('setState', {key: 'key', value: 'value'});
     * @param {*} state 
     * @param {*} param1 
     */
    setState(state, { key, value }) {
        state[key] = value;
    }
    // 可以在这里添加更多的 mutations  
};