<template>
  <div style="
      background-color: rgba(33, 34, 68, 1);
      width: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    ">
    <van-row>
      <van-col span="24" style="
          height: 80px;
          line-height: 80px;
          width: 100%;
          text-align: center;
          font-size: 0.8125rem;
        ">
        {{ $t("Statistics") }} {{ token_name }}
      </van-col>
      <van-col span="24" style="
          height: 80px;
          line-height: 80px;
          width: 100%;
          text-align: center;
          font-size: 1.875rem;
        ">
        CUSDT {{ $getters.account.balance }}
        <span style="font-size: 1.875rem; color: rgba(112, 128, 179, 1)">{{
          token_name
        }}</span>
      </van-col>
    </van-row>
  </div>
  <div style="width: 100%; padding: 30px; box-sizing: border-box">
    <van-row>
      <van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
        ">
        <van-row>
          <van-col span="12">
            {{ $t("Address") }}
          </van-col>
          <van-col span="12" style="text-align: right">
            {{ $getters.account.address_masked }}
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        ">
        <van-row>
          <van-col span="24" style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
            <van-row>
              <van-col span="12">
                {{ $t("Exchange Rate") }}
              </van-col>
              <van-col span="12" style="text-align: right">
                1:1
                <span style="color: rgba(112, 128, 179, 1)">CUSDT</span>
              </van-col>
            </van-row>
          </van-col>
          <van-col span="24" style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
            <van-row>
              <van-col span="12">
                {{ $t("Convertible") }}
              </van-col>
              <van-col span="12" style="text-align: right">
                {{ $getters.account.interest_balance }}
                <span style="color: rgba(112, 128, 179, 1)">CUSDT</span>
              </van-col>
            </van-row>
          </van-col>
          <van-col span="24" style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
            <van-row>
              <van-col span="24">
                {{ $t("Exchange Amount") }}
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-row>
                  <van-col span="8">
                    <input type="number" style="
                        height: 31px;
                        padding: 6px 10px;
                        border-radius: 15px;
                        box-sizing: border-box;
                        background-color: #2c2c53;
                        border: none;
                      " placeholder="0.00" v-model="change_price" />
                  </van-col>
                  <van-col span="16" style="text-align: right">
                    <div style="
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: flex-end;
                      ">
                      <span style="margin-left: 5px">{{ token_name }}</span>
                      <van-button color="#f90" size="mini" round style="margin-left: 5px">Max</van-button>
                    </div>
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-button color="rgba(107,84,238,1)" size="large" @click="duihuan">{{
          $t("Exchange Now")
        }}</van-button>
      </van-col>
      <!-- <van-col span="24" style="margin-top: 20px">
        <van-button color="#363762" size="large">{{
          $t("Exchange History")
        }}</van-button>
      </van-col> -->
    </van-row>
  </div>
  <div
    style="width: 100%; padding: 30px; box-sizing: border-box; border-radius: 20px; background-color: #212244; margin-top: 20px">
    <van-row>
      <van-col span="24" style="font-size: 1.5rem">
        {{ $t("Revenue Distribution") }}
      </van-col>
      <van-col span="24" style="margin-top: 20px;color: #7080b3">
        <van-row>
          <van-col span="8">
            {{ $t("Level") }}
          </van-col>
          <van-col span="8" style="text-align: center">
            {{ $t("Period") }}
          </van-col>
          <van-col span="8" style="text-align: right">
            {{ $t("Income") }}(%)
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px" v-for="(item, index) in levelList" :key="index">
          <van-col span="8">
            {{ item.level_name }}
          </van-col>
          <van-col span="8" style="text-align: center">
            {{ item.min_balance }}
          </van-col>
          <van-col span="8" style="text-align: right">
            {{ item.min_income }}%
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div style="margin-top: 20px; background-color: #212244; padding: 30px">
    <van-row>
      <van-col span="24">
        {{ $t("FAQ") }}
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-row gutter="20">
          <van-col span="4">
            <img :src="require('@/assets/image/info1.png')" alt="" style="height: 32px; width: 32px" />
          </van-col>
          <van-col span="4">
            <img :src="require('@/assets/image/info2.png')" alt="" style="height: 32px; width: 32px" />
          </van-col>
          <van-col span="4">
            <img :src="require('@/assets/image/info3.png')" alt="" style="height: 32px; width: 32px" />
          </van-col>
          <van-col span="4">
            <img :src="require('@/assets/image/info4.png')" alt="" style="height: 32px; width: 32px" />
          </van-col>
          <van-col span="4">
            <img :src="require('@/assets/image/info5.png')" alt="" style="height: 32px; width: 32px" />
          </van-col>
          <van-col span="4">
            <img :src="require('@/assets/image/info6.png')" alt="" style="height: 32px; width: 32px" />
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div style="height: 90px"></div>
</template>

<script>
export default {
  name: "change",
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      levelList: [
        {
          level_name: "primary",
          min_balance: "30",
          min_income: "1.5"
        },
        {
          level_name: "intermediate",
          min_balance: "60day",
          min_income: "2"
        },
        {
          level_name: "senior",
          min_balance: "90day",
          min_income: "3"
        },
      ],
      change_price: 0.0,
      tronweb: "",
      address: "",
      balance: "",
      token_name: "",
      token_price: "",
      token_number: 0,
      usdt_contract_address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
    };
  },
  methods: {
    getLevel() {
      this.levelList = [];
      // this.$request.post("index/getLevel", "").then(res => {
      //   if (res.data.code === 200) {
      //     this.levelList = res.data.data;
      //   }
      // })
    },
    duihuan() {
      $.ajax({
        url: this.baseURL + "/api/index/duihuan",
        type: "post",
        data: {
          address: this.address,
          money: this.change_price,
        },
        success: (res) => {
          this.$toast(res.msg);
          location.reload();
          //   console.log(res);
        },
      });
    },
  },
  mounted() {
    // 获取sessionStorage中的地址
    this.getLevel();
  },
};
</script>

<style scoped></style>
